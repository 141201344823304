
  import { defineComponent, onMounted, reactive } from "vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import store from "@/store";
  import { Mutations, Actions } from "@/store/enums/StoreEnums";
  
  import DataMapping from "@/components/1crm/product/data-kartu-termapping/data-kartu-termapping.vue";
  // import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";
  
  export default defineComponent({
    name: "kt-widget-11",
    components: {
      DataMapping,
      // UploadComponent,
    },
    props: {
      widgetClasses: String,
    },
  
    beforeMount() {
      store.dispatch(Actions.VERIFY_AUTH);
  
      setCurrentPageBreadcrumbs("Data Mapping", ["Data"]);
    },
  });
  