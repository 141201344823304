
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import DownloadSekolah from "@/components/1crm/product/data-kartu-termapping/download.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
    DownloadSekolah,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [
        {
          nfcIdbaru: "",
          nfcId: "",
          merchantName: "",
          purchaseDate: "",
          schoolName: "",
          accountName: "",
          mappingDate: "",
          activateDate: "",
          cardStatus: "",
        },
      ],
      listCategory: [],
      listStatus: [],
      listSekolah: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      role: "",
      cardId: "",
      schoolId: "",
      accountName: "",
      totalCardSuccess: "",
      totalCardActive: "",
      status: false,
      totalCardBlank: "",
      totalCardFailed: "",
      name: "",
      roleuser: "",
      nfcId: "",
      nfcIdbaru: "",
      categoryId: "",
      cardStatus: "",
      filter: false,
      combostatus: [
        {
          name: "AKTIF",
          _id: "ACTIVE",
        },
        {
          name: "BELUM DIGUNAKAN",
          _id: "INACTIVE",
        },
        {
          name: "HILANG",
          _id: "LOST",
        },
        {
          name: "RUSAK",
          _id: "DAMAGED",
        },
      ],
      detail: {
        activateDate: "",
      },
      formDetail: {
        schoolName2: "-",
        schoolName: "",
        accountName2: "-",
        accountName: "",
        nfcId: "",
        cardStatus: "",
        nfcIdbaru: "",
        note: "",
      },
    };
  },

  beforeMount() {
    // store.dispatch(Actions.VERIFY_AUTH);
    this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    this.getData(this.paging.size, this.paging.page);
    this.getListKategori();
    this.getListSekolah();
    setCurrentPageBreadcrumbs("Data Produk", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      // let search = "";
      // let valcategoryId = "";
      // if (this.categoryId) {
      //   valcategoryId = this.categoryId;
      // }
      // if (this.name) {
      //   search = this.name;
      // }
      let valschoolId = "";
      if (this.schoolId) {
        valschoolId = this.schoolId;
      }
      let search4 = "";
      if (this.cardStatus) {
        search4 = "cardStatus=" + this.cardStatus + "&";
      } else {
        search4;
      }
      let search3 = "";
      if (this.nfcId) {
        search3 = "nfcId=" + this.nfcId + "&";
      } else {
        search3;
      }
      let search2 = "";
      if (this.accountName) {
        search2 = "accountName=" + this.accountName + "&";
      } else {
        search2;
      }

      var office = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      console.log(this.roleuser);
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" +
          office +
          "stock/all?" +
          search2 +
          search3 +
          search4 +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=1&schoolId=" +
          valschoolId
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.getSummary();
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    filterClick() {
      this.filter = !this.filter;
    },
    getSummary() {
      var office = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" + office + "stock/summary"
      )
        .then(({ data }) => {
          this.totalCardActive = data.totalCardActive;
          this.totalCardSuccess = data.totalCardSuccess;
          this.totalCardFailed = data.totalCardFailed;
          this.totalCardBlank = data.totalCardBlank;

          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    reset() {
      this.status = false;
      this.formDetail = {
        schoolName2: "-",
        schoolName: "",
        accountName2: "-",
        accountName: "",
        nfcId: "",
        cardStatus: "",
        nfcIdbaru: "",
        note: "",
      };
    },
    getListKategori() {
      ApiService.getWithoutSlug("crmv2/main_order/product/combo_catagory").then(
        ({ data }) => {
          this.listCategory = data;
          localStorage.setItem("listCategory", JSON.stringify(data));
        }
      );
    },
    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    formatDate(value) {
      return moment(value).format("DD MMMM YYYY");
    },

    pilihSekolah(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.schoolId = event;
      } else {
        this.schoolId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    getId(index, item) {
      this.cardId = item._id;

      this.isLoading = true;
      const toast = useToast();
      var office = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.getWithoutSlug(
        "crmv2/main_card/card_stock/" + office + item._id
      )
        .then((res) => {
          console.log(res.data);
          this.formDetail.schoolName = res.data.schoolName;
          this.formDetail.accountName = res.data.accountName;
          this.formDetail.nfcId = res.data.nfcId;
          this.formDetail.nfcIdbaru = res.data.nfcIdbaru;

          this.formDetail.note = res.data.note;

          // this.formDetail.progress_responsible = res.data.progress_responsible;
          // this.formDetail.sales_area_name =
          //   res.data.sales_area_name || "Sekolah Belum Memiliki Sales";
          // this.formDetail.confirmation = res.data.confirmation;
          // this.formDetail.note = res.data.note;
          // this.formDetail.initialContractStatus = res.data.legal_status;
          // this.formDetail.progress_status = progressStatus;

          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
    dateTime1(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    dateTime2(value) {
      return moment(value).format("DD MMMM YYYY HH:mm:ss");
    },
    pilihstatus(event) {
      if (event) {
        this.cardStatus = event;
      } else {
        this.cardStatus = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },
    pilihKategori(event) {
      // console.log(event.target.value);
      console.log(event);
      // this.schoolId = event;
      if (event) {
        this.categoryId = event;
      } else {
        this.categoryId = "";
      }
      // this.getQtyStudent();
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      const toast = useToast();
      this.isLoading = true;
      this.cardId = item._id;
      var office = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }

      ApiService.delete(
        "crmv2/main_card/card_stock/" + office + "stock/" + item._id
      )
        .then((res) => {
          toast.success("Kartu berhasil dihapus");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Tidak dapat menghapus kartu yang sudah aktif");
          this.isLoading = false;
        });
    },
    update() {
      const toast = useToast();
      var body = {
        cardStatus: this.formDetail.cardStatus,
        note: this.formDetail.note,
        nfcId: this.formDetail.nfcIdbaru,
      };
      console.log(body);

      console.log(this.formDetail.cardStatus);
      if (this.formDetail.cardStatus == "") {
        return toast.error("Pilih Status Wajib Diisi");
      }
      var office = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        office = "";
      } else {
        office = "office/";
      }
      ApiService.putWithData(
        "crmv2/main_card/card_stock/" + office + this.cardId,
        body
      )
        .then((resp) => {
          toast.success("Berhasil Update");
          // setTimeout(() => {
          window.location.reload();
          // }, 3000);
          this.isLoading = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          // if (this.formDetail.note == "" && this.formDetail.nfcIdbaru !== "" ){
          //   return toast.error("Alasan Diganti Wajib Diisi")
          // }
          // if (this.formDetail.note == "" ){
          //   return toast.error("Alasan Diganti Wajib Diisi")
          // }
          this.isLoading = false;
        });
    },
    download() {
      const toast = useToast();
      this.isLoading = true;
      console.log(this.cardStatus);
      var sekolah = "";
      if (this.roleuser == "ADMIN_SCHOOL") {
        sekolah = JSON.parse(localStorage.getItem("user_account")!).schoolId;
      } else {
        sekolah = this.schoolId;
      }
      var Status =
        this.cardStatus == "" ? "" : `&cardStatus=${this.cardStatus}`;
      ApiService.getDownload(
        `crmv2/main_card/card_stock/download_mapped?&sort_by=nama&sort_dir=-1&schoolId=${sekolah}&nfcId=${this.nfcId}&accountName=${this.accountName}${Status}`
      )
        .then((response) => {
          this.handleBlobResponse(response.data, "xlsx");
          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },

    // getuu() {
    // let schoolId = "";
    // if (this.schoolId) {
    //   schoolId = "schoolId=" + this.schoolId + "&";
    // } else {
    //   schoolId;
    // }

    //   ApiService.getWithoutSlug("crmv2/main_card/card_stock/stock/summary")
    //     .then(({ data }) => {
    //       this.totalCardActive = data.totalCardActive;
    //       this.totalCardSuccess = data.totalCardSuccess;
    //       this.totalCardBlank = data.totalCardBlank;
    //       this.totalCardFailed = data.totalCardFailed;
    //     })
    //     .catch(({ response }) => {});
    // },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    handleBlobResponse(blobFileData, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      const d = Date.now();
      link.href = url;
      link.setAttribute(
        "download",
        "Download Data Kartu Mapping" + "." + extension
      ); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    changeModal() {},

    dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "-";
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
});
